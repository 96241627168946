import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Checkbox,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";
import { User, UserResponse } from "../../types/users";
import { userService } from "../../apis/users";
import NotificationPopup from "./NotificationPopup";
import { EditorProvider } from "react-simple-wysiwyg";

const RiderTable: React.FC = () => {
  const [riders, setRiders] = useState<User[]>([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [search, setSearch] = useState("");
  const [total, setTotal] = useState(0);
  const [selectedRiders, setSelectedRiders] = useState<string[]>([]);
  const [role, setRole] = useState("all");
  const [minTotalRides, setMinTotalRides] = useState<number | "">("");
  const [maxTotalRides, setMaxTotalRides] = useState<number | "">("");
  const [country, setCountry] = useState("all");
  const [filtersVisible, setFiltersVisible] = useState(false); // State to toggle filters visibility
  const countries: Record<string, string> = { uk: "UK", france: "FRANCE" };
  const loadRiders = async () => {
    try {
      // Construct the params object conditionally
      const params: any = {
        search,
        page: page + 1, // API uses 1-based page index
        limit: rowsPerPage,
        minRides: minTotalRides,
        maxRides: maxTotalRides,
        countryCode: country === "all" ? "" : country,
      };

      // Only add userType if role is not used
      if (!role) {
        params.userType = "rider";
      } else {
        params.role = role === "all" ? "" : role;
      }

      const data: UserResponse = await userService.getUsers(params);
      setTotal(data.total);
      setRiders(data.users);
    } catch (error) {
      console.error("Error fetching riders:", error);
    }
  };

  useEffect(() => {
    loadRiders();
  }, [page, rowsPerPage, search, role, minTotalRides, maxTotalRides, country]);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
    setPage(0); // Reset to first page on search
  };

  const handlePageChange = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset to first page on rows per page change
  };

  const handleSelectRider = (id: string) => {
    setSelectedRiders((prev) =>
      prev.includes(id) ? prev.filter((riderId) => riderId !== id) : [...prev, id]
    );
  };

  const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const allIds = riders.map((rider) => rider._id);
      setSelectedRiders(allIds);
    } else {
      setSelectedRiders([]);
    }
  };

  const handleNotification = (type: "sms" | "email" | "push") => {
    console.log(`Sending ${type} to:`, selectedRiders);
    // Call the appropriate API to send notifications here
  };

  return (
    <Box marginTop={"10px"}>
      {/* Toggle Filters Button */}
      <Button
        variant="outlined"
        onClick={() => setFiltersVisible((prev) => !prev)}
        sx={{ marginBottom: 2 }}
      >
        {filtersVisible ? "Hide Filters" : "Show Filters"}
      </Button>
      {/* Filters section */}
      {filtersVisible && (
        <Box sx={{ display: "flex", gap: 2, marginBottom: 2, flexWrap: "wrap" }}>
          <TextField
            label="Search Riders by name, email & phone number"
            value={search}
            onChange={handleSearchChange}
            fullWidth
          />
          <FormControl sx={{ minWidth: 150 }}>
            <InputLabel>Role</InputLabel>
            <Select value={role} onChange={(e) => setRole(e.target.value)} label="Role">
              <MenuItem value="all">All</MenuItem>
              <MenuItem value="user">User</MenuItem>
              <MenuItem value="pro-user">Pro-User</MenuItem>
            </Select>
          </FormControl>
          <TextField
            label="Min Total Rides"
            type="number"
            value={minTotalRides}
            onChange={(e) => setMinTotalRides(e.target.value ? +e.target.value : "")}
          />
          <TextField
            label="Max Total Rides"
            type="number"
            value={maxTotalRides}
            onChange={(e) => setMaxTotalRides(e.target.value ? +e.target.value : "")}
          />
          {/* Country Filter */}
          <FormControl sx={{ minWidth: 150 }}>
            <InputLabel>Country</InputLabel>
            <Select value={country} onChange={(e) => setCountry(e.target.value)} label="Country">
              <MenuItem value="all">All</MenuItem>
              <MenuItem value="uk">UK</MenuItem>
              <MenuItem value="france">France</MenuItem>
            </Select>
          </FormControl>
        </Box>
      )}
      <TableContainer>
        <Table>
          <TableHead style={{ backgroundColor: "rgb(224, 224, 224)" }}>
            <TableRow>
              <TableCell padding="checkbox">
                <Checkbox
                  indeterminate={selectedRiders.length > 0 && selectedRiders.length < riders.length}
                  checked={selectedRiders.length === riders.length && riders.length > 0}
                  onChange={handleSelectAll}
                />
              </TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Phone</TableCell>
              <TableCell>Total Ride Count</TableCell>
              <TableCell>Country</TableCell>
              <TableCell>Role</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {riders.map((rider) => (
              <TableRow key={rider._id} selected={selectedRiders.includes(rider._id)}>
                <TableCell padding="checkbox">
                  <Checkbox
                    checked={selectedRiders.includes(rider._id)}
                    onChange={() => handleSelectRider(rider._id)}
                  />
                </TableCell>
                <TableCell>{`${rider.firstName} ${rider.lastName}`}</TableCell>
                <TableCell>{rider.email}</TableCell>
                <TableCell>{rider.phone}</TableCell>
                <TableCell>{rider.totalRideCount || 0}</TableCell>
                <TableCell>{rider.countryCode ? countries[rider.countryCode] : "-"}</TableCell>
                <TableCell>{rider.role}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        count={total}
        page={page}
        onPageChange={handlePageChange}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleRowsPerPageChange}
      />
      <EditorProvider>
        <NotificationPopup
          disabled={selectedRiders.length === 0}
          selectedUsers={riders.filter((rider) => selectedRiders.includes(rider._id))}
        ></NotificationPopup>
      </EditorProvider>
    </Box>
  );
};

export default RiderTable;
