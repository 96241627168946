import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Modal,
  TextField,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  IconButton,
  Typography,
  Container,
  Grid,
  Paper,
  Toolbar,
} from "@mui/material";
import { SelectChangeEvent } from "@mui/material"; // Import SelectChangeEvent
import CloseIcon from "@mui/icons-material/Close";
import { templateService } from "../../apis/templates"; // Assuming templateService is imported
import { Template, MessageType } from "../../types/templates"; // Assuming types are imported
import {
  Editor,
  BtnUndo,
  BtnRedo,
  Separator,
  BtnBold,
  BtnItalic,
  BtnUnderline,
  BtnStrikeThrough,
  BtnNumberedList,
  BtnBulletList,
  BtnLink,
  BtnClearFormatting,
  HtmlButton,
  BtnStyles,
} from "react-simple-wysiwyg";
import { User } from "../../types/users";
import {
  ManualEmailNotification,
  ManualPushNotification,
  ManualSMSNotification,
} from "../../types/notification";
import { notificationService } from "../../apis/manualnotification";

const NotificationPopup: React.FC<{ disabled: boolean; selectedUsers: User[] }> = ({
  disabled,
  selectedUsers,
}) => {
  const [open, setOpen] = useState(false);
  const [selectedMessageType, setSelectedMessageType] = useState<MessageType | "">("");
  const [selectedTemplate, setSelectedTemplate] = useState<Template | null>(null);
  const [templates, setTemplates] = useState<Template[]>([]);
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const fetchTemplates = async () => {
    try {
      const response = await templateService.getTemplates();
      setTemplates(response);
    } catch (error) {
      console.error("Error fetching templates:", error);
    }
  };

  useEffect(() => {
    if (open) {
      fetchTemplates();
    }
  }, [open]);

  const handleOpen = (messageType: MessageType) => {
    setSelectedMessageType(messageType);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedTemplate(null);
    setSubject("");
    setMessage("");
  };

  const handleTemplateChange = (e: SelectChangeEvent<string>) => {
    const templateId = e.target.value;

    if (templateId === "none") {
      setSelectedTemplate(null);
      setSubject("");
      setMessage("");
    } else {
      const template = templates.find((tpl) => tpl._id === templateId) || null;
      setSelectedTemplate(template);

      if (template) {
        if (selectedMessageType === MessageType.EMAIL) {
          setSubject(template.title);
          setMessage(template.message);
        } else if (selectedMessageType === MessageType.PUSH_NOTIFICATION) {
          setSubject(template.title);
          setMessage(template.message);
        } else {
          setMessage(template.message);
        }
      }
    }
  };
  const handleSendNotification = async () => {
    setLoading(true);
    try {
      if (selectedMessageType === MessageType.SMS) {
        // Call the API for sending SMS
        const smsPayload: ManualSMSNotification = {
          phoneNumbers: selectedUsers.map((user) => user.phone), // Assuming `phoneNumber` exists in user object
          message,
        };
        await notificationService.sendSMS(smsPayload);
      } else if (selectedMessageType === MessageType.EMAIL) {
        // Call the API for sending Email
        const emailPayload: ManualEmailNotification = {
          emails: selectedUsers.map((user) => user.email), // Assuming `email` exists in user object
          subject, // Using subject as the title
          message,
        };
        await notificationService.sendEmail(emailPayload);
      } else if (selectedMessageType === MessageType.PUSH_NOTIFICATION) {
        // Call the API for sending Push Notification
        const pushPayload: ManualPushNotification = {
          userIds: selectedUsers.map((user) => user._id), // Assuming `_id` exists in user object
          title: subject, // Using subject as the title
          message,
        };
        await notificationService.sendPush(pushPayload);
      }

      handleClose();
    } catch (error) {
      console.error("Error sending notification:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box>
      <Box sx={{ display: "flex", gap: 2, marginBottom: 2, flexWrap: "wrap" }}>
        <Button
          variant="contained"
          color="primary"
          disabled={disabled}
          onClick={() => handleOpen(MessageType.SMS)}
        >
          Send SMS
        </Button>
        <Button
          variant="contained"
          color="secondary"
          disabled={disabled}
          onClick={() => handleOpen(MessageType.EMAIL)}
        >
          Send Email
        </Button>
        <Button
          variant="contained"
          color="success"
          disabled={disabled}
          onClick={() => handleOpen(MessageType.PUSH_NOTIFICATION)}
        >
          Send Push
        </Button>
      </Box>

      <Modal open={open} onClose={handleClose}>
        <Box sx={modalStyle}>
          <Paper sx={{ padding: 3 }}>
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <Typography variant="h6">
                {selectedMessageType === MessageType.SMS
                  ? "Send SMS"
                  : selectedMessageType === MessageType.EMAIL
                  ? "Send Email"
                  : "Send Push Notification"}{" "}
                to Selected Users
              </Typography>
              <IconButton onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </Box>

            <Grid container spacing={2} marginTop={1}>
              {/* Template Selection */}
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel>Template</InputLabel>
                  <Select
                    value={selectedTemplate?._id || "none"}
                    onChange={(e) => handleTemplateChange(e)}
                    label="Select Template"
                  >
                    <MenuItem value="none">None</MenuItem>
                    {templates.map((template) => (
                      <MenuItem key={template._id} value={template._id}>
                        {template.title}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              {/* Subject (Only for Email or Push Notification) */}
              {(selectedMessageType === MessageType.EMAIL ||
                selectedMessageType === MessageType.PUSH_NOTIFICATION) && (
                <Grid item xs={12}>
                  <TextField
                    label="Subject"
                    value={subject}
                    onChange={(e) => setSubject(e.target.value)}
                    fullWidth
                  />
                </Grid>
              )}

              {/* Message Box */}
              <Grid item xs={12}>
                <Box>
                  <Typography variant="subtitle1">* Message</Typography>
                  <Editor
                    value={message}
                    onChange={(e) => {
                      setMessage(e.target.value);
                    }}
                    style={{
                      border: "1px solid #ccc",
                      borderRadius: "4px",
                      padding: "10px",
                      minHeight: "100px",
                    }}
                  >
                    <Toolbar
                      style={{
                        padding: "4px", // Reduce padding
                        margin: "0", // Remove margin
                        height: "20px", // Set a fixed height
                        display: "flex",
                        alignItems: "center",
                        minHeight: "unset",
                        backgroundColor: "#f5f5f5",
                      }}
                    >
                      <BtnUndo />
                      <BtnRedo />
                      <Separator />
                      <BtnBold />
                      <BtnItalic />
                      <BtnUnderline />
                      <BtnStrikeThrough />
                      <Separator />
                      <BtnNumberedList />
                      <BtnBulletList />
                      <Separator />
                      <BtnLink />
                      <BtnClearFormatting />
                      <HtmlButton />
                      <Separator />
                      <BtnStyles />
                    </Toolbar>
                  </Editor>
                </Box>
              </Grid>

              {/* Action buttons */}
              <Grid item xs={12}>
                <Box display="flex" justifyContent="flex-end" gap={2}>
                  <Button variant="text" onClick={handleClose} disabled={loading}>
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSendNotification}
                    disabled={
                      selectedMessageType === MessageType.SMS
                        ? !message || loading
                        : !subject || !message || loading
                    }
                  >
                    {loading ? "Sending..." : "Send"}
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Paper>
        </Box>
      </Modal>
    </Box>
  );
};

// Styling for the modal
const modalStyle = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "100vh",
  overflow: "auto",
};

export default NotificationPopup;
