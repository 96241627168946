import React, { useState, useEffect } from "react";
import {
  Box,
  TextField,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Button,
  Checkbox,
} from "@mui/material";
import { userService } from "../../apis/users";
import { User, UserResponse } from "../../types/users";
import NotificationPopup from "./NotificationPopup";
import { EditorProvider } from "react-simple-wysiwyg";

const DriverTable: React.FC = () => {
  const [drivers, setDrivers] = useState<User[]>([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [search, setSearch] = useState("");
  const [total, setTotal] = useState(0);
  const [selectedDrivers, setSelectedDrivers] = useState<string[]>([]);
  // Filter states
  const [status, setStatus] = useState<string | "">("all");
  const [driverType, setDriverType] = useState<string | "">("all");
  const [carType, setCarType] = useState<string | "">("all");
  const [minRejectedRides, setMinRejectedRides] = useState<number | "">("");
  const [maxRejectedRides, setMaxRejectedRides] = useState<number | "">("");
  const [minTotalRides, setMinTotalRides] = useState<number | "">("");
  const [maxTotalRides, setMaxTotalRides] = useState<number | "">("");
  const [country, setCountry] = useState<string | "">("all");
  const [city, setCity] = useState<string | "">("all");
  const [driverPlan, setDriverPlan] = useState<string | "">("all");
  const [filtersVisible, setFiltersVisible] = useState(false); // State to toggle filters visibility

  const loadDrivers = async (currentPage: number, limit: number) => {
    try {
      const data: UserResponse = await userService.getUsers({
        userType: "driver",
        search,
        page: currentPage + 1, // API expects 1-based page index
        limit,
        status: status === "all" ? "" : status,
        driverType: driverType === "all" ? "" : driverType,
        carType: carType === "all" ? "" : carType,
        minRejectedRides,
        maxRejectedRides,
        minTotalRides,
        maxTotalRides,
        country:
          country === "all" ? "" : country === "uk" ? "GB" : country === "france" ? "FR" : "",
        city: city === "all" ? "" : city,
        driverPlan: driverPlan === "all" ? "" : driverPlan,
      });
      setDrivers(data.users);
      setTotal(data.total);
    } catch (error) {
      console.error("Error fetching drivers:", error);
    }
  };

  useEffect(() => {
    loadDrivers(page, rowsPerPage);
  }, [
    page,
    rowsPerPage,
    search,
    status,
    driverType,
    carType,
    minRejectedRides,
    maxRejectedRides,
    minTotalRides,
    maxTotalRides,
    country,
    city,
    driverPlan,
  ]);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
    setPage(0); // Reset to first page on search
  };

  const handlePageChange = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset to first page on rows per page change
  };

  const handleSelectDriver = (id: string) => {
    setSelectedDrivers((prev) =>
      prev.includes(id) ? prev.filter((driverId) => driverId !== id) : [...prev, id]
    );
  };

  const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const allIds = drivers.map((driver) => driver._id);
      setSelectedDrivers(allIds);
    } else {
      setSelectedDrivers([]);
    }
  };

  const handleNotification = (type: "sms" | "email" | "push") => {
    console.log(`Sending ${type} to:`, selectedDrivers);
    // Call the appropriate API to send notifications here
  };
  return (
    <Box marginTop={"10px"}>
      {/* Toggle Filters Button */}
      <Button
        variant="outlined"
        onClick={() => setFiltersVisible((prev) => !prev)}
        sx={{ marginBottom: 2 }}
      >
        {filtersVisible ? "Hide Filters" : "Show Filters"}
      </Button>
      {/* Filters section */}
      {filtersVisible && (
        <Box>
          <TextField
            label="Search Drivers by name, email & phone number"
            value={search}
            onChange={handleSearchChange}
            fullWidth
          />
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "repeat(3, 1fr)",
              gap: 2,
              marginBottom: 2,
              marginTop: 2,
            }}
          >
            {/* Status Filter */}
            <FormControl fullWidth>
              <InputLabel>Status</InputLabel>
              <Select value={status} onChange={(e) => setStatus(e.target.value)} label="Status">
                <MenuItem value="all">All</MenuItem>
                <MenuItem value="approved">Approved</MenuItem>
                <MenuItem value="document_verified">Document Verified</MenuItem>
                <MenuItem value="bank_details_added">Bank Details Added</MenuItem>
                <MenuItem value="rejected">Rejected</MenuItem>
              </Select>
            </FormControl>

            {/* Driver Type Filter */}
            <FormControl fullWidth>
              <InputLabel>Driver Type</InputLabel>
              <Select
                value={driverType}
                onChange={(e) => setDriverType(e.target.value)}
                label="Driver Type"
              >
                <MenuItem value="all">All</MenuItem>
                <MenuItem value="normal">Normal</MenuItem>
                <MenuItem value="professional">Professional</MenuItem>
              </Select>
            </FormControl>

            {/* Car Type Filter */}
            <FormControl fullWidth>
              <InputLabel>Car Type</InputLabel>
              <Select value={carType} onChange={(e) => setCarType(e.target.value)} label="Car Type">
                <MenuItem value="all">All</MenuItem>
                <MenuItem value="GREEN">Classic</MenuItem>
                <MenuItem value="GREEN">Green</MenuItem>
                <MenuItem value="VAN">Van</MenuItem>
              </Select>
            </FormControl>

            {/* Min & Max Rejected Rides */}
            <Box sx={{ display: "flex", gap: 2 }}>
              <TextField
                label="Min Rejected Rides"
                value={minRejectedRides}
                onChange={(e) => setMinRejectedRides(e.target.value ? +e.target.value : "")}
                type="number"
                fullWidth
              />
              <TextField
                label="Max Rejected Rides"
                value={maxRejectedRides}
                onChange={(e) => setMaxRejectedRides(e.target.value ? +e.target.value : "")}
                type="number"
                fullWidth
              />
            </Box>

            {/* Min & Max Total Rides */}
            <Box sx={{ display: "flex", gap: 2 }}>
              <TextField
                label="Min Total Rides"
                value={minTotalRides}
                onChange={(e) => setMinTotalRides(e.target.value ? +e.target.value : "")}
                type="number"
                fullWidth
              />
              <TextField
                label="Max Total Rides"
                value={maxTotalRides}
                onChange={(e) => setMaxTotalRides(e.target.value ? +e.target.value : "")}
                type="number"
                fullWidth
              />
            </Box>

            {/* Country Filter */}
            <FormControl fullWidth>
              <InputLabel>Country</InputLabel>
              <Select value={country} onChange={(e) => setCountry(e.target.value)} label="Country">
                <MenuItem value="all">All</MenuItem>
                <MenuItem value="uk">UK</MenuItem>
                <MenuItem value="france">France</MenuItem>
              </Select>
            </FormControl>

            {/* City Filter */}
            <FormControl fullWidth>
              <InputLabel>City</InputLabel>
              <Select value={city} onChange={(e) => setCity(e.target.value)} label="City">
                <MenuItem value="all">All</MenuItem>
                {country === "uk" && <MenuItem value="london-boroughs">London Boroughs</MenuItem>}
                {country === "france" && <MenuItem value="ile-de-France">Ile-de-France</MenuItem>}
              </Select>
            </FormControl>

            {/* Driver Plan Filter */}
            <FormControl fullWidth>
              <InputLabel>Driver Plan</InputLabel>
              <Select
                value={driverPlan}
                onChange={(e) => setDriverPlan(e.target.value)}
                label="Driver Plan"
              >
                <MenuItem value="all">All</MenuItem>
                <MenuItem value="is_premium">Premium</MenuItem>
                <MenuItem value="is_community">Community</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </Box>
      )}

      <TableContainer>
        <Table>
          <TableHead style={{ backgroundColor: "rgb(224, 224, 224)" }}>
            <TableRow>
              <TableCell padding="checkbox">
                <Checkbox
                  indeterminate={
                    selectedDrivers.length > 0 && selectedDrivers.length < drivers.length
                  }
                  checked={selectedDrivers.length === drivers.length && drivers.length > 0}
                  onChange={handleSelectAll}
                />
              </TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Phone</TableCell>
              <TableCell>Total Rides</TableCell>
              <TableCell>Rejected Rides</TableCell>
              <TableCell>Country</TableCell>
              <TableCell>City</TableCell>
              <TableCell>Driver Type</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Driver Plan</TableCell>
              <TableCell>Car Type</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {drivers.map((driver) => (
              <TableRow key={driver._id}>
                <TableCell padding="checkbox">
                  <Checkbox
                    checked={selectedDrivers.includes(driver._id)}
                    onChange={() => handleSelectDriver(driver._id)}
                  />
                </TableCell>
                <TableCell>{`${driver.firstName} ${driver.lastName}`}</TableCell>
                <TableCell>{driver.email}</TableCell>
                <TableCell>{driver.phone || "-"}</TableCell>
                <TableCell>{driver.totalRideCount || 0}</TableCell>
                <TableCell>{driver.rejectedRideCount || 0}</TableCell>
                <TableCell>{driver.address?.country || "-"}</TableCell>
                <TableCell>{driver.address?.city || "-"}</TableCell>
                <TableCell>{driver.driverType || "Normal"}</TableCell>
                <TableCell>{driver.vtcStatus}</TableCell>
                <TableCell>
                  {driver.is_premium_vtc
                    ? "Premiun"
                    : driver.is_community_vtc
                    ? "Community"
                    : "Normal"}
                </TableCell>
                <TableCell>{driver.carType}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        component="div"
        count={total}
        page={page}
        onPageChange={handlePageChange}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleRowsPerPageChange}
        rowsPerPageOptions={[10, 25, 50, 100]}
      />
      <EditorProvider>
        <NotificationPopup
          disabled={selectedDrivers.length === 0}
          selectedUsers={drivers.filter((driver) => selectedDrivers.includes(driver._id))}
        ></NotificationPopup>
      </EditorProvider>
    </Box>
  );
};

export default DriverTable;
