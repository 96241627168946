import axios from "axios";

import { Template, CreateTemplateDTO, UpdateTemplateDTO } from "../types/templates";

const baseUrl = process.env.REACT_APP_NOTIFICATION_SERVICE_BASE_URL;
const apiKey = process.env.REACT_APP_NOTIFICATION_SERVICE_API_KEY;
const getAuthConfig = () => {
  return {
    headers: {
      "api-key": apiKey,
    },
  };
};

export const templateService = {
  getTemplates: async (): Promise<Template[]> => {
    const config = getAuthConfig();
    const response = await axios.get<Template[]>(`${baseUrl}/message-templates`, config);
    return response.data;
  },

  getTemplateById: async (id: string): Promise<Template | null> => {
    const config = getAuthConfig();
    const response = await axios.get<Template>(`${baseUrl}/message-templates/${id}`, config);
    return response.data || null;
  },

  createTemplate: async (data: CreateTemplateDTO): Promise<Template> => {
    const config = getAuthConfig();
    const response = await axios.post<Template>(`${baseUrl}/message-templates`, data, config);
    return response.data;
  },

  updateTemplate: async (id: string, data: UpdateTemplateDTO): Promise<Template> => {
    const config = getAuthConfig();
    const response = await axios.put<Template>(`${baseUrl}/message-templates/${id}`, data, config);
    return response.data;
  },

  deleteTemplate: async (id: string): Promise<string> => {
    const config = getAuthConfig();
    const response = await axios.delete<{ message: string }>(
      `${baseUrl}/message-templates/${id}`,
      config
    );
    return response.data.message;
  },
};
