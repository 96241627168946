import React, { useState, useEffect } from "react";
import {
  Box,
  Paper,
  Typography,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import SidePanel from "../../components/SidePanel";
import TopBar from "../../components/TopBar";
import { Template } from "../../types/templates";
import { templateService } from "../../apis/templates";

const NotificationTemplateManagement: React.FC = () => {
  const [allTemplates, setAllTemplates] = useState<Template[]>([]); // Holds all templates
  const [templates, setTemplates] = useState<Template[]>([]); // Holds templates for the current page
  const [page, setPage] = useState(0); // Current page
  const [rowsPerPage, setRowsPerPage] = useState(10); // Rows per page
  const navigate = useNavigate();

  useEffect(() => {
    const loadTemplates = async () => {
      try {
        const data = await templateService.getTemplates(); // Get all templates in one call
        setAllTemplates(data);
      } catch (error) {
        console.error("Error fetching templates:", error);
      }
    };
    loadTemplates();
  }, []);

  // Handle slicing of data for the current page
  useEffect(() => {
    const startIndex = page * rowsPerPage;
    const endIndex = startIndex + rowsPerPage;
    setTemplates(allTemplates.slice(startIndex, endIndex)); // Slice the data for the current page
  }, [allTemplates, page, rowsPerPage]);

  const handleDelete = async (id: string) => {
    try {
      await templateService.deleteTemplate(id);
      setAllTemplates((prev) => prev.filter((template) => template._id !== id));
    } catch (error) {
      console.error("Error deleting template:", error);
    }
  };

  const handleAddTemplate = () => navigate("/notifications/create");
  const handleEditTemplate = (id: string) => navigate(`/notifications/edit/${id}`);

  const handlePageChange = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset to the first page
  };

  return (
    <Box className="mainContainer">
      <Box className="sidePanelContainer">
        <SidePanel isOpen={true} onClose={() => {}} />
      </Box>
      <Box className="dashboardContainer">
        <TopBar />
        <Box style={{ width: "100%", padding: "20px", marginTop: "5%" }}>
          <Paper elevation={3} sx={{ padding: "20px", marginBottom: "20px" }}>
            <Box sx={{ display: "flex", justifyContent: "space-between", marginBottom: "20px" }}>
              <Typography variant="h5">Manage Notification Templates</Typography>
              <Button variant="contained" color="primary" onClick={handleAddTemplate}>
                Add Notification Template
              </Button>
            </Box>
            <TableContainer>
              <Table>
                <TableHead style={{ backgroundColor: "rgb(224, 224, 224)" }}>
                  <TableRow>
                    <TableCell className="cellContainer">Title</TableCell>
                    <TableCell className="cellContainer">Type</TableCell>
                    <TableCell className="cellContainer">Event Type</TableCell>
                    <TableCell className="cellContainer">Created At</TableCell>
                    <TableCell align="right" className="cellContainer">
                      Actions
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {templates.map((template) => (
                    <TableRow key={template._id}>
                      <TableCell>{template.title}</TableCell>
                      <TableCell>{template.type}</TableCell>
                      <TableCell>{template.eventType}</TableCell>
                      <TableCell>{new Date(template.createdAt).toLocaleDateString()}</TableCell>
                      <TableCell align="right">
                        <Button
                          variant="outlined"
                          color="primary"
                          onClick={() => handleEditTemplate(template._id)}
                          sx={{ marginRight: "10px" }}
                        >
                          Edit
                        </Button>
                        <Button
                          variant="outlined"
                          color="error"
                          onClick={() => handleDelete(template._id)}
                        >
                          Delete
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              component="div"
              count={allTemplates.length} // Total number of templates
              page={page}
              onPageChange={handlePageChange}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleRowsPerPageChange}
            />
          </Paper>
        </Box>
      </Box>
    </Box>
  );
};

export default NotificationTemplateManagement;
