// src/types/Template.ts
export enum MessageType {
  SMS = "sms",
  EMAIL = "email",
  PUSH_NOTIFICATION = "push",
}

export interface Template {
  _id: string;
  title: string;
  message: string; // Rich text content
  type: MessageType;
  eventType: string;
  createdAt: string; // ISO format date
  updatedAt: string; // ISO format date
}

export interface CreateTemplateDTO {
  title: string;
  message: string;
  type: MessageType;
  eventType: string;
}

export interface UpdateTemplateDTO {
  title?: string;
  message?: string;
  type?: MessageType;
  eventType?: string;
}
