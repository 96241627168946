import React, { useState, useEffect } from "react";
import {
  Box,
  Paper,
  TextField,
  Typography,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { CreateTemplateDTO, UpdateTemplateDTO, MessageType } from "../../types/templates";
import { templateService } from "../../apis/templates";
import SidePanel from "../../components/SidePanel";
import TopBar from "../../components/TopBar";
import {
  BtnBold,
  BtnBulletList,
  BtnClearFormatting,
  BtnItalic,
  BtnLink,
  BtnNumberedList,
  BtnRedo,
  BtnStrikeThrough,
  BtnStyles,
  BtnUnderline,
  BtnUndo,
  ContentEditableEvent,
  Editor,
  HtmlButton,
  Separator,
  Toolbar,
} from "react-simple-wysiwyg";

const AddEditNotificationTemplate: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [formData, setFormData] = useState<CreateTemplateDTO | UpdateTemplateDTO>({
    title: "",
    type: undefined,
    eventType: "",
    message: "",
  });
  const [errors, setErrors] = useState({
    title: false,
    type: false,
    eventType: false,
    message: false,
  });

  useEffect(() => {
    if (id) {
      const loadTemplate = async () => {
        try {
          const data = await templateService.getTemplateById(id);
          if (data) setFormData(data);
        } catch (error) {
          console.error("Error loading template:", error);
        }
      };
      loadTemplate();
    }
  }, [id]);

  const handleChange = (
    e:
      | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      | SelectChangeEvent<string>
      | ContentEditableEvent
  ) => {
    const { name, value } = e.target;

    setFormData((prev) => ({
      ...prev,
      [name || "message"]: value, // Default to "message" if no name attribute
    }));
    setErrors((prev) => ({ ...prev, [name || "message"]: false })); // Reset error
  };

  const validateForm = () => {
    const isMessageEmpty = !formData.message?.replace(/<\/?[^>]+(>|$)/g, "").trim(); // Remove HTML tags and trim
    const newErrors = {
      title: !formData.title?.trim(),
      type: !formData.type,
      eventType: !formData.eventType?.trim(),
      message: isMessageEmpty,
    };
    setErrors(newErrors);
    return !Object.values(newErrors).some((error) => error); // Check if any field has an error
  };

  const handleSave = async () => {
    if (!validateForm()) {
      return;
    }

    try {
      if (id) {
        await templateService.updateTemplate(id, formData as UpdateTemplateDTO);
      } else {
        await templateService.createTemplate(formData as CreateTemplateDTO);
      }
      navigate("/notifications");
    } catch (error) {
      console.error("Error saving template:", error);
    }
  };

  return (
    <Box className="mainContainer">
      <Box className="sidePanelContainer">
        <SidePanel isOpen={true} onClose={() => {}} />
      </Box>
      <Box className="dashboardContainer">
        <TopBar />
        <Box style={{ width: "100%", padding: "20px", marginTop: "5%" }}>
          <Paper elevation={3} sx={{ padding: "20px", margin: "20px" }}>
            <Typography variant="h5">
              {id ? "Edit Notification Template" : "Add Notification Template"}
            </Typography>
            <Box component="form" sx={{ marginTop: "20px" }}>
              <TextField
                label="* Title"
                name="title"
                fullWidth
                value={formData.title}
                onChange={handleChange}
                margin="normal"
                error={errors.title}
                helperText={errors.title ? "Title is required" : ""}
              />
              <FormControl fullWidth margin="normal" error={errors.type}>
                <Select
                  name="type"
                  value={formData.type || ""}
                  displayEmpty={true}
                  onChange={(e) => handleChange(e as SelectChangeEvent<string>)}
                >
                  <MenuItem value="" disabled>
                    * Select Type
                  </MenuItem>
                  <MenuItem value="email">Email</MenuItem>
                  <MenuItem value="sms">SMS</MenuItem>
                  <MenuItem value="push">Push Notification</MenuItem>
                </Select>
                {errors.type && <Typography color="error">Type is required</Typography>}
              </FormControl>
              <TextField
                label="* Event Type"
                name="eventType"
                fullWidth
                value={formData.eventType}
                onChange={handleChange}
                margin="normal"
                error={errors.eventType}
                helperText={errors.eventType ? "Event type is required" : ""}
              />
              <Box style={{ marginBottom: "20px" }}>
                <Typography variant="subtitle1">* Message</Typography>
                <Editor
                  value={formData.message}
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  style={{
                    border: errors.message ? "1px solid red" : "1px solid #ccc",
                    borderRadius: "4px",
                    padding: "10px",
                    minHeight: "100px",
                  }}
                >
                  <Toolbar>
                    <BtnUndo />
                    <BtnRedo />
                    <Separator />
                    <BtnBold />
                    <BtnItalic />
                    <BtnUnderline />
                    <BtnStrikeThrough />
                    <Separator />
                    <BtnNumberedList />
                    <BtnBulletList />
                    <Separator />
                    <BtnLink />
                    <BtnClearFormatting />
                    <HtmlButton />
                    <Separator />
                    <BtnStyles />
                  </Toolbar>
                </Editor>
                {errors.message && (
                  <Typography color="error" sx={{ marginTop: "8px" }}>
                    Message is required
                  </Typography>
                )}
              </Box>

              <Box sx={{ display: "flex", justifyContent: "flex-end", marginTop: "20px" }}>
                <Button
                  variant="outlined"
                  onClick={() => navigate("/notifications")}
                  sx={{ marginRight: "10px" }}
                >
                  Cancel
                </Button>
                <Button variant="contained" color="primary" onClick={handleSave}>
                  Save
                </Button>
              </Box>
            </Box>
          </Paper>
        </Box>
      </Box>
    </Box>
  );
};

export default AddEditNotificationTemplate;
