import {
  ManualEmailNotification,
  ManualPushNotification,
  ManualSMSNotification,
} from "./../types/notification";
import axios from "axios";

import { Template, CreateTemplateDTO, UpdateTemplateDTO } from "../types/templates";

const baseUrl = process.env.REACT_APP_NOTIFICATION_SERVICE_BASE_URL;
const apiKey = process.env.REACT_APP_NOTIFICATION_SERVICE_API_KEY;
const getAuthConfig = () => {
  return {
    headers: {
      "api-key": apiKey,
    },
  };
};

export const notificationService = {
  sendSMS: async (data: ManualSMSNotification): Promise<any> => {
    const config = getAuthConfig();
    const response = await axios.post<Template>(
      `${baseUrl}/manual-notification/sendSMS`,
      data,
      config
    );
    return response.data;
  },
  sendEmail: async (data: ManualEmailNotification): Promise<any> => {
    const config = getAuthConfig();
    const response = await axios.post<Template>(
      `${baseUrl}/manual-notification/sendEmail`,
      data,
      config
    );
    return response.data;
  },
  sendPush: async (data: ManualPushNotification): Promise<any> => {
    const config = getAuthConfig();
    const response = await axios.post<Template>(
      `${baseUrl}/manual-notification/sendPush`,
      data,
      config
    );
    return response.data;
  },
};
