/* Component for displaying driver document details. */

import {
  Box,
  Typography,
  Grid,
  Button,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  Paper,
  Modal,
  TextField,
  CircularProgress,
  Snackbar,
  Alert,
  AccordionDetails,
  AccordionSummary,
  Accordion,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  IconButton,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SidePanel from "../../components/SidePanel";
import TopBar from "../../components/TopBar";
import {
  connectAccount,
  fileUploadToStripe,
  getDriver,
  getStripeAccount,
  getStripeBankAccount,
  updateDriver,
  updateVtcStatus,
} from "../../apis/drivers";

import "./style.css";
import {
  BtnBold,
  BtnBulletList,
  BtnClearFormatting,
  BtnItalic,
  BtnLink,
  BtnNumberedList,
  BtnRedo,
  BtnUnderline,
  BtnUndo,
  ContentEditableEvent,
  Editor,
  Toolbar,
} from "react-simple-wysiwyg";
import { styled } from "@mui/material/styles";
import { getTransactionsList, getWallet, handleTransactionUpdate } from "../../apis/wallet";
import { Transaction, TransactionResponse, TransactionType, Wallet } from "../../types/wallet";
const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const DriverDetails = () => {
  const { driver } = useSelector((state: any) => state.driverReducer);
  const params = useParams();
  const navigate = useNavigate();

  const [driverData, setDriverData] = useState(driver);
  const [openRejectDocModal, setOpenRejectDocModal] = useState(false);
  const [openAddBankDetailsModal, setAddBankDetailsModal] = useState(false);
  const [rejectRemark, setRejectRemark] = useState("");
  const [loading, setLoading] = useState(false);
  const [approveSnackbar, setApproveSnackbar] = React.useState(false);
  const [failedSnackbar, setFailedSnackbar] = React.useState(false);
  const [rejectSnackbar, setRejectSnackbar] = React.useState(false);
  const [documentVerifiedSnackbar, setDocumentVerifiedSnackbar] = React.useState(false);
  const [bankDetailsSnackbar, setBankDetailSnackbar] = React.useState(false);
  const [savingBankDetailsLoader, setSavingBankDetailsLoader] = React.useState(false);
  const driverId = params.id;
  const [selectedDocuments, setSelectedDocuments] = useState<string[]>([]);
  const [franceAccountHolderName, setFranceAccountHolderName] = useState("");
  const [ibanNumber, setIbanNumber] = useState("");
  const [ukAccountHolderName, setUkAccountHolderName] = useState("");
  const [ukAccountNumber, setUkAccountNumber] = useState("");
  const [sortCode, setSortCode] = useState("");
  const [frontPreview, setFrontPreview] = useState(null);
  const [backPreview, setBackPreview] = useState(null);
  const [walletTransactions, setWalletTransactions] = useState<Transaction[]>([]);
  const [walletData, setWalletData] = useState<Wallet>();
  const [isAddingTransaction, setIsAddingTransaction] = useState(false);
  const [transactionAmount, setTransactionAmount] = useState("");
  const [transactionDescription, setTransactionDescription] = useState("");

  const handleSave = async () => {
    const amount = parseFloat(transactionAmount);
    if (walletData && driverId && !isNaN(amount) && amount !== 0) {
      const payload: Transaction = {
        user_id: driverId,
        wallet_id: walletData._id,
        amount: amount,
        statement: transactionDescription,
        currency: walletData.currency,
        type: amount < 0 ? TransactionType.DEBIT : TransactionType.CREDIT,
      };
      // Call the API to update the transaction
      await handleTransactionUpdate(payload);
      await getTransactions(driverId);
      await getWalletData(driverId, walletData.country_code, walletData.region_code);
      setIsAddingTransaction(false);
      setTransactionAmount(""); // Clear the input
      setTransactionDescription("");
    } else {
      alert("Please enter a valid number (non-zero).");
    }
  };

  const handleCancel = () => {
    setIsAddingTransaction(false);
    setTransactionAmount("");
    setTransactionDescription("");
  };
  const setFilePreview = (
    frontUrl: string,
    backUrl: string,
    setFrontPreview: any,
    setBackPreview: any
  ) => {
    setFrontPreview(frontUrl);
    setBackPreview(backUrl);
  };

  const handleUpload = async (countryCode: string): Promise<string[]> => {
    if (!frontPreview || !backPreview) return [];
    const fileUrlsForStripe = [frontPreview, backPreview];
    let fileIds: string[] = [];
    for (const fileName of fileUrlsForStripe) {
      let body = {
        fileUrl: fileName,
        countryCode: countryCode,
      };
      const fileUploadResponse = await fileUploadToStripe(body);
      if (fileUploadResponse.status == 200) {
        fileIds.push(fileUploadResponse.data.fileId);
      }
    }
    return fileIds;
  };

  const handleCheckboxChange = (event: any) => {
    const { name, checked } = event.target;
    if (checked) {
      setSelectedDocuments([...selectedDocuments, name]);
    } else {
      setSelectedDocuments(selectedDocuments.filter((doc) => doc !== name));
    }
  };
  const carDocumentList: { [key: string]: string } = {
    CarRegistrationPhoto: "Car Registration Photo",
    vtcStickerPhoto: "VTC Sticker Photo",
    insurancePhoto: "Insurance Photo",
    photoOfVehicle: "Photo of Vehicle",
    vehicleTechnicalInspectionFiles: "Vehicle Technical Inspection Files",
    PHVKDoc: "PHVK Document",
    insuranceCertificate: "Insurance Certificate",
    insuranceSupporting: "Insurance Supporting Documents",
    motcertificate: "MOT Certificate",
    logBook: "Log Book",
  };
  const documentTitlesList = {
    france: {
      "profile-picture": "Profile Picture",
      nic: "Nic",
      "driver-license": "Driver License (Front and Back)",
      rib: "RIB",
      kbis: "KBIS",
      "bank-detail-document": "Bank details for the bank account",
      "vtc-taxi-card": "VTC or taxi card",
      "vtc-sticker-photo": "VTC sticker photo",
      "insurance-paid": "Insurance (paid, vehicle, business RC insurance)",
      "car-registration": "Car registration document",
      "vehicle-technical": "Vehicle technical inspection (Completed within the last year)",
      "photo-of-vehicle": "Photo of the vehicle with visible registration plate",
      "france-id": "France ID",
      "europe-passport": "Europe Passport",
    },
    uk: {
      "id-document": "Proof of Identity",
      "profile-picture": "Profile Picture",
      "dvla-plastic": "DVLA Plastic driving license",
      "dvla-electronic": "DVLA electronic counterpart check code",
      "private-hire": "Private hire driving licence/PCO licence",
      "bank-detail-document": "Bank details for the bank account",
      "phvl-license": "PHVL Licence from an accepted council",
      "insurance-certificate": "Insurance certificate",
      "insurance-supporting": "Insurance supporting documents",
      "mot-test": "MOT Test Certificate",
      "log-book": "Logbook",
      "photo-of-vehicle": "Photo of the vehicle with visible registration plate",
      "europe-passport": "Europe Passport",
    },
  };

  const documentTitles =
    driverData.countryCode === "france" || driverData.countryCode === "ivory-coast"
      ? documentTitlesList.france
      : documentTitlesList.uk;

  //methods
  const goToBack = () => navigate("/driver-documentation");

  const formatDate = (timestamp: string) => {
    var parsedTimestamp = new Date(timestamp);

    // Format it as "Dec 12, 2023"
    var options: any = { year: "numeric", month: "short", day: "numeric" };
    var formattedTimestamp = parsedTimestamp.toLocaleDateString("en-US", options);

    return formattedTimestamp;
  };

  function refreshPage() {
    window.location.reload();
  }

  const handleOpen = () => setOpenRejectDocModal(true);
  const handleBankDetailsModal = () => setAddBankDetailsModal(true);
  const handleClose = () => {
    setSelectedDocuments([]);
    setRejectRemark("");
    setOpenRejectDocModal(false);
  };
  const handleBankDetailsModalClose = () => {
    setFrontPreview(null);
    setBackPreview(null);
    setUkAccountHolderName("");
    setUkAccountNumber("");
    setSortCode("");
    setFranceAccountHolderName("");
    setIbanNumber("");
    setAddBankDetailsModal(false);
    setSavingBankDetailsLoader(false);
  };

  const openSnackBar = () => {
    setApproveSnackbar(true);
  };
  const closeSnackBar = (event: React.SyntheticEvent | Event, reason?: string) => {
    setApproveSnackbar(false);
  };

  const openSnackBarBankDetails = () => {
    setBankDetailSnackbar(true);
    setFrontPreview(null);
    setBackPreview(null);
    setSavingBankDetailsLoader(false);
  };
  const closeSnackBarBankDetails = (event: React.SyntheticEvent | Event, reason?: string) => {
    setBankDetailSnackbar(false);
  };

  const openRejctSnackBar = () => {
    setRejectSnackbar(true);
  };
  const closeRejectSnackBar = (event: React.SyntheticEvent | Event, reason?: string) => {
    setRejectSnackbar(false);
  };

  const openDocumentVerifiedSnackbar = () => {
    setDocumentVerifiedSnackbar(true);
  };
  const closeDocumentVerifiedSnackbar = (event: React.SyntheticEvent | Event, reason?: string) => {
    setDocumentVerifiedSnackbar(false);
  };

  const handleError = () => {
    setFailedSnackbar(true);
    setSavingBankDetailsLoader(false);
  };
  const updateDocumentVerified = async () => {
    //API for approving the driver
    const body = {
      vtcStatus: "document_verified",
      remark: "",
      rejectedDocuments: [],
    };
    let res = await updateVtcStatus(driverId, body);
    if (res?.status === 200) {
      openDocumentVerifiedSnackbar();
      refreshPage();
    } else {
      setFailedSnackbar(true);
    }
  };

  const approveDocument = async () => {
    //API for approving the driver
    const body = {
      vtcStatus: "approved",
      remark: "",
      rejectedDocuments: [],
    };
    let res = await updateVtcStatus(driverId, body);
    if (res?.status === 200) {
      openSnackBar();
      refreshPage();
    } else {
      setFailedSnackbar(true);
    }
  };

  const rejectDocument = async () => {
    //API for rejecting the driver
    const body = {
      vtcStatus: "rejected",
      remark: rejectRemark,
      rejectedDocuments: selectedDocuments,
    };
    let res = await updateVtcStatus(driverId, body);
    if (res?.status === 200) {
      openRejctSnackBar();
      refreshPage();
    } else {
      setFailedSnackbar(true);
    }
  };

  const onHandleChange = (event: any) => {
    setRejectRemark(event.target.value);
  };

  const getDriverById = async (id: any) => {
    setLoading(true);
    const data = await getDriver(id);
    if (data) {
      setDriverData(data);
      //console.log(data);
      const documentList: any[] = data["documents"];
      console.log(documentList);
      let frontUrl = "";
      let backUrl = "";

      if (data["countryCode"] === "france") {
        console.log("france", data["countryCode"]);
        frontUrl = documentList.find((document) => document.name === "nicFront")["url"];
        backUrl = documentList.find((document) => document.name === "nicBack")["url"];
      } else if (data["countryCode"] === "uk"){
        console.log("uk", data["countryCode"]);
        frontUrl = documentList.find((document) => document.name === "ProofOfIdentityFront")["url"];
        backUrl = documentList.find((document) => document.name === "ProofOfIdentityBack")["url"];
      }
      setFilePreview(frontUrl, backUrl, setFrontPreview, setBackPreview);
      if (data["countryCode"] === "ivory-coast") {
        getWalletData(id, data["countryCode"], "abidjan");
        getTransactions(id);
      }
    }
    setLoading(false);
  };

  const getWalletData = async (id: string, countryCode: string, regionCode: string) => {
    const walletData = await getWallet(id, countryCode, regionCode);
    if (walletData) {
      setWalletData(walletData);
    }
  };
  const getTransactions = async (id: string) => {
    const transactionResponse: TransactionResponse = await getTransactionsList(id);
    if (transactionResponse.transactions && transactionResponse.transactions.length > 0) {
      setWalletTransactions(transactionResponse.transactions);
    }
  };

  const readableDate = (timestamp: string) => {
    const newDate = new Date(timestamp).toLocaleString("en-US", {
      dateStyle: "medium", // Medium date format (e.g., Dec 9, 2024)
      timeStyle: "short", // Short time format (e.g., 11:12 AM)
    });
    return newDate;
  };

  const handleBankDetailsSubmit = async (country: string) => {
    try {
      if (!frontPreview || !backPreview) return;
      // for france
      if (country == "france") {
        if (!franceAccountHolderName || !ibanNumber) return;
        setSavingBankDetailsLoader(true);
        const idDocuments: string[] = await handleUpload(country);
        if (!idDocuments.length) {
          setFailedSnackbar(true);
          return;
        }
        let countryCode = "FR";
        let currency = "eur";
        let accountHolderName = franceAccountHolderName;
        let accountNumber = ibanNumber;

        const body = {
          "bank_account[country]": countryCode,
          "bank_account[currency]": currency,
          "bank_account[account_holder_name]": accountHolderName,
          "bank_account[account_number]": accountNumber,
        };
        const stripeBankAccountResponse = await getStripeBankAccount(body, country);
        if (stripeBankAccountResponse && stripeBankAccountResponse.data) {
          let bankAccountToken = stripeBankAccountResponse.data.id;
          let body = {
            "account[business_type]": "individual",
            "account[individual][first_name]": driverData.firstName,
            "account[individual][last_name]": driverData.lastName,
            "account[individual][email]": driverData.email,
            "account[individual][phone]": driverData.phone,
            "account[individual][address][country]": "FR",
            "account[individual][address][city]": driverData?.address?.city,
            "account[individual][address][state]": driverData?.address?.state,
            "account[individual][address][postal_code]": driverData?.address?.postal_code,
            "account[individual][address][line1]": driverData?.address?.line_1,
            "account[individual][dob][day]": driverData?.date_of_birth?.day,
            "account[individual][dob][month]": driverData?.date_of_birth?.month,
            "account[individual][dob][year]": driverData?.date_of_birth?.year,
            "account[individual][verification][document][front]": idDocuments[0],
            "account[individual][verification][document][back]": idDocuments[1],
            "account[company][name]": `${driverData.firstName} ${driverData.lastName}`,
            "account[company][phone]": driverData.phone,
            "account[company][address][country]": driverData?.address?.country,
            "account[company][address][city]": driverData?.address?.city,
            "account[company][address][state]": driverData?.address?.state,
            "account[company][address][postal_code]": driverData?.address?.postal_code,
            "account[company][address][line1]": driverData?.address?.line_1,
            "account[tos_shown_and_accepted]": true,
          };
          const stripeAccountResponse = await getStripeAccount(body, country);
          if (stripeAccountResponse && stripeAccountResponse.data) {
            let stripeAccountToken = stripeAccountResponse.data.id;
            let body = {
              tokenAccount: stripeAccountToken,
              tokenBankAccount: bankAccountToken,
              country: "fr",
              driverId: driverData._id,
              driverEmail: driverData.email,
            };
            const connectAccountResponse = await connectAccount(body);
            if (connectAccountResponse) {
              const connectedAccountId = connectAccountResponse.data.connectedAccountId;
              let body = {
                connectedAccountId: connectedAccountId,
                vtcStatus: "bank_details_added",
              };
              const updateDriverResponse = await updateDriver(body, driverData._id);
              if (updateDriverResponse?.status === 200) {
                openSnackBarBankDetails();
                refreshPage();
              } else {
                handleError();
              }
            }
          }
        }
      } else {
        // for uk
        if (!ukAccountHolderName || !ukAccountNumber || !sortCode) return;
        setSavingBankDetailsLoader(true);
        const idDocuments: string[] = await handleUpload(country);
        if (!idDocuments.length) {
          handleError();
          return;
        }
        let countryCode = "GB";
        let currency = "GBP";
        let accountHolderName = ukAccountHolderName;
        let accountNumber = ukAccountNumber;
        const body = {
          "bank_account[country]": countryCode,
          "bank_account[currency]": currency,
          "bank_account[account_holder_name]": accountHolderName,
          "bank_account[routing_number]": sortCode,
          "bank_account[account_number]": accountNumber,
        };
        const stripeBankAccountResponse = await getStripeBankAccount(body, country);
        if (stripeBankAccountResponse && stripeBankAccountResponse.data) {
          let bankAccountToken = stripeBankAccountResponse.data.id;
          let body = {
            "account[business_type]": "individual",
            "account[individual][first_name]": driverData.firstName,
            "account[individual][last_name]": driverData.lastName,
            "account[individual][email]": driverData.email,
            "account[individual][phone]": driverData.phone,
            "account[individual][address][country]": "GB",
            "account[individual][address][city]": driverData?.address?.city,
            "account[individual][address][state]": driverData?.address?.state,
            "account[individual][address][postal_code]": driverData?.address?.postal_code,
            "account[individual][address][line1]": driverData?.address?.line_1,
            "account[individual][dob][day]": driverData?.date_of_birth?.day,
            "account[individual][dob][month]": driverData?.date_of_birth?.month,
            "account[individual][dob][year]": driverData?.date_of_birth?.year,
            "account[individual][verification][document][front]": idDocuments[0],
            "account[individual][verification][document][back]": idDocuments[1],
            "account[company][name]": `${driverData.firstName} ${driverData.lastName}`,
            "account[company][phone]": driverData.phone,
            "account[company][address][country]": driverData?.address?.country,
            "account[company][address][city]": driverData?.address?.city,
            "account[company][address][state]": driverData?.address?.state,
            "account[company][address][postal_code]": driverData?.address?.postal_code,
            "account[company][address][line1]": driverData?.address?.line_1,
            "account[tos_shown_and_accepted]": true,
          };
          const stripeAccountResponse = await getStripeAccount(body, country);
          if (stripeAccountResponse && stripeAccountResponse.data) {
            let stripeAccountToken = stripeAccountResponse.data.id;
            let body = {
              tokenAccount: stripeAccountToken,
              tokenBankAccount: bankAccountToken,
              country: "uk",
              driverId: driverData._id,
              driverEmail: driverData.email,
            };
            const connectAccountResponse = await connectAccount(body);
            if (connectAccountResponse) {
              const connectedAccountId = connectAccountResponse.data.connectedAccountId;
              let body = {
                connectedAccountId: connectedAccountId,
                vtcStatus: "bank_details_added",
              };
              const updateDriverResponse = await updateDriver(body, driverData._id);
              if (updateDriverResponse?.status === 200) {
                openSnackBarBankDetails();
                refreshPage();
              } else {
                handleError();
              }
            }
          }
        }
      }
    } catch (e: any) {
      handleError();
      alert(
        "Error occurred: " +
          (e?.response?.data?.error?.message ? e?.response?.data?.error?.message : e.message)
      );
    }
  };

  const getDriverStatus = (status: string) => {
    switch (status) {
      case "document_verified":
        return "Documents Verified";
      case "bank_details_added":
        return "Bank Details Added";
      case "approved":
        return "Approved";
      case "rejected":
        return "Rejected";
      default:
        return "Pending";
    }
  };

  useEffect(() => {
    const id = driverId !== undefined ? driverId : "";
    getDriverById(id);
  }, []);
  const formatTime = (isoString: string) => {
    const date = new Date(isoString);

    // Format the date and time based on the device's locale and options
    return date.toLocaleString(undefined, {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: true, // Change this to false if you want a 24-hour format
    });
  };
  return (
    <Box className="mainContainer">
      <Box className="sidePanelContainer">
        <SidePanel isOpen={true} onClose={() => {}} />
      </Box>
      <Box className="dashboardContainer">
        <TopBar />
        <Box style={{ width: "100%", marginTop: "5%" }} component={Paper}>
          {loading ? (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginTop: "200px",
              }}
            >
              <CircularProgress />
            </Box>
          ) : (
            <Box>
              <Typography
                style={{
                  fontSize: 16,
                  fontWeight: 700,
                  margin: "30px 0px 30px 0px",
                  backgroundColor: "#9BCBEC",
                  padding: "15px",
                }}
              >
                Driver Details
              </Typography>
              <Box style={{ margin: "20px" }}>
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <Typography className="detailsLabel">Driver Name</Typography>
                    <Typography>
                      {driverData.firstName} {driverData.lastName}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography className="detailsLabel">Phone</Typography>
                    <Typography>{driverData.phone}</Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography className="detailsLabel">Email</Typography>
                    <Typography>{driverData.email}</Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography className="detailsLabel">Driver Type</Typography>
                    <Typography>
                      {driverData.driver_type ? driverData.driver_type : "Normal"}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography className="detailsLabel">Shared Ride Enabled</Typography>
                    {driverData.driver_type && driverData.driver_type === "professional" && (
                      <Typography>-</Typography>
                    )}
                    {!driverData.driver_type ||
                      (driverData.driver_type && driverData.driver_type === "normal" && (
                        <Typography>{driverData.accept_shared_rides ? "Yes" : "No"}</Typography>
                      ))}
                  </Grid>
                  <Grid item xs={4}>
                    <Typography className="detailsLabel">Rating</Typography>
                    <Typography>{driverData?.rating && (driverData?.rating).toFixed(2)}</Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography className="detailsLabel">Status</Typography>
                    <Typography className="textCapitalize">
                      {getDriverStatus(driverData?.vtcStatus)}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography className="detailsLabel">
                      Stripe Account Verification Status
                    </Typography>
                    <Typography className="textCapitalize">
                      {driverData.stripeAccountVerificationStatus
                        ? driverData.stripeAccountVerificationStatus
                        : "pending"}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography className="detailsLabel">City</Typography>
                    <Typography>{driverData?.address?.city}</Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography className="detailsLabel">Rejected Ride Count</Typography>
                    <Typography>{driverData.rejectedRideCount || 0}</Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography className="detailsLabel">Created at</Typography>
                    <Typography>{formatTime(driverData.createdAt)}</Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography className="detailsLabel">Updated at</Typography>
                    <Typography>{formatTime(driverData.updatedAt)}</Typography>
                  </Grid>
                </Grid>
              </Box>
              <Box>
                <Typography
                  style={{
                    fontWeight: "bold",
                    fontSize: 16,
                    marginTop: "50px",
                    marginBottom: "50px",
                    //borderTop: "solid 1px grey",
                    backgroundColor: "#9BCBEC",
                    padding: "15px",
                  }}
                >
                  Driver Documents
                </Typography>
                {driverData?.documents?.length > 0 ? (
                  <Box style={{ margin: "20px" }}>
                    <TableContainer component={Paper}>
                      <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead style={{ backgroundColor: "rgb(224, 224, 224)" }}>
                          <TableRow>
                            <TableCell className="cellContainer">ID</TableCell>
                            <TableCell className="cellContainer">File</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {driverData.documents.map((row: any) => {
                            return (
                              <TableRow
                                key={row._id}
                                sx={{
                                  "&:last-child td, &:last-child th": { border: 0 },
                                }}
                              >
                                <TableCell component="th" scope="row">
                                  {row._id}
                                </TableCell>
                                <TableCell>
                                  <a href={row.url} target="_blank">
                                    {row.name}
                                  </a>
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Box>
                ) : (
                  <Typography style={{ textAlign: "center" }}>No Documents</Typography>
                )}
              </Box>
              <Box>
                <Typography
                  style={{
                    fontWeight: "bold",
                    fontSize: 18,
                    marginTop: "50px",
                    marginBottom: "50px",
                    backgroundColor: "#9BCBEC",
                    padding: "15px",
                  }}
                >
                  Cars
                </Typography>
                {driverData.cars.map((item: any) => (
                  <Box style={{ margin: "20px" }} key={item._id}>
                    <Accordion elevation={4}>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography className="detailsLabel">Model: {item.car_model}</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Grid container spacing={2}>
                          <Grid item xs={4}>
                            <Typography className="detailsLabel">Car Maker</Typography>
                            <Typography>{item.car_make ? item.car_make : "--"}</Typography>
                          </Grid>
                          <Grid item xs={4}>
                            <Typography className="detailsLabel">Year</Typography>
                            <Typography>{item.car_year ? item.car_year : "--"}</Typography>
                          </Grid>
                          <Grid item xs={4}>
                            <Typography className="detailsLabel">License Plate</Typography>
                            <Typography>
                              {item.license_plate ? item.license_plate : "--"}
                            </Typography>
                          </Grid>
                          <Grid item xs={4}>
                            <Typography className="detailsLabel">Car Type</Typography>
                            <Typography>{item.car_type ? item.car_type : "--"}</Typography>
                          </Grid>
                          <Grid item xs={4}>
                            <Typography className="detailsLabel">Doors</Typography>
                            <Typography>{item.doors ? item.doors : "--"}</Typography>
                          </Grid>
                          <Grid item xs={4}>
                            <Typography className="detailsLabel">No. of Seats</Typography>
                            <Typography>{item.total_seats ? item.total_seats : "--"}</Typography>
                          </Grid>
                          <Grid item xs={4}>
                            <Typography className="detailsLabel">Status</Typography>
                            <Typography>{item.status ? item.status : "--"}</Typography>
                          </Grid>
                          <Grid item xs={4}>
                            <Typography className="detailsLabel">VIN</Typography>
                            <Typography>{item.vin ? item.vin : "--"}</Typography>
                          </Grid>
                          <Grid item xs={4}>
                            <Typography className="detailsLabel">VTC</Typography>
                            <Typography>{item.vtc ? "Yes" : "No"}</Typography>
                          </Grid>
                          <Grid item xs={4}>
                            <Typography className="detailsLabel">Available</Typography>
                            <Typography>{item.available ? "Yes" : "No"}</Typography>
                          </Grid>
                          <Grid item xs={4}>
                            <Typography className="detailsLabel">Maintained At</Typography>
                            <Typography>
                              {item.maintained_at ? formatDate(item.maintained_at) : "--"}
                            </Typography>
                          </Grid>
                          <Grid item xs={4}>
                            <Typography className="detailsLabel">Created At</Typography>
                            <Typography>
                              {item.created_at ? formatDate(item.created_at) : "--"}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Box>
                          <Typography
                            style={{
                              fontWeight: "bold",
                              marginTop: "50px",
                              marginBottom: "50px",
                              borderTop: "solid 1px grey",
                              paddingTop: "2%",
                            }}
                          >
                            Documents
                          </Typography>
                          {item?.list_document_id?.length > 0 ? (
                            <TableContainer component={Paper}>
                              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                <TableHead style={{ backgroundColor: "rgb(224, 224, 224)" }}>
                                  <TableRow>
                                    <TableCell align="center" className="cellContainer">
                                      Document Name
                                    </TableCell>
                                    <TableCell align="center" className="cellContainer">
                                      File
                                    </TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {item?.list_document_id?.map((row: any, index: number) => {
                                    return typeof row === "string" ? null : (
                                      <TableRow
                                        key={`car-doc_${index}`}
                                        sx={{
                                          "&:last-child td, &:last-child th": { border: 0 },
                                        }}
                                      >
                                        <TableCell component="th" scope="row">
                                          {carDocumentList[row.name]}
                                        </TableCell>
                                        <TableCell>
                                          <a href={row.url} target="_blank">
                                            {row.name}
                                          </a>
                                        </TableCell>
                                      </TableRow>
                                    );
                                  })}
                                </TableBody>
                              </Table>
                            </TableContainer>
                          ) : (
                            <Typography style={{ textAlign: "center" }}>No Documents</Typography>
                          )}
                        </Box>
                      </AccordionDetails>
                    </Accordion>
                  </Box>
                ))}
              </Box>
              {driverData && driverData.countryCode === "ivory-coast" && (
                <Box style={{ padding: "20px", border: "1px solid #ddd", borderRadius: "8px" }}>
                  <Typography
                    style={{
                      fontWeight: "bold",
                      fontSize: 16,
                      marginTop: "10px",
                      marginBottom: "20px",
                      backgroundColor: "#9BCBEC",
                      padding: "15px",
                    }}
                  >
                    Driver Wallet
                  </Typography>

                  {/* Wallet Balance Section */}
                  <Box style={{ marginBottom: "20px" }}>
                    <Grid container alignItems="center" spacing={2}>
                      {/* Wallet Balance Label and Value */}
                      <Grid item xs={8}>
                        <Typography className="detailsLabel">Wallet Balance</Typography>
                        <Typography>
                          {walletData?.currency.toUpperCase()} {walletData?.wallet_balance}
                        </Typography>
                      </Grid>

                      {/* Add Transaction Button */}
                      <Grid item xs={4} style={{ textAlign: "right" }}>
                        {!isAddingTransaction && (
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() => setIsAddingTransaction(true)}
                          >
                            Add Transaction
                          </Button>
                        )}
                      </Grid>
                    </Grid>
                  </Box>

                  {/* Add Transaction Section (Visible after clicking "Add Transaction") */}
                  {isAddingTransaction && (
                    <Box
                      style={{
                        marginTop: "20px",
                        padding: "20px",
                        borderRadius: "8px",
                        backgroundColor: "#f4f4f4",
                        boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                      }}
                    >
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <TextField
                            type="number"
                            label="Transaction Amount"
                            value={transactionAmount}
                            onChange={(e) => setTransactionAmount(e.target.value)}
                            fullWidth
                            style={{ marginBottom: "20px" }}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            type="text"
                            label="Transaction Description"
                            value={transactionDescription}
                            onChange={(e) => setTransactionDescription(e.target.value)}
                            fullWidth
                            style={{ marginBottom: "20px" }}
                          />
                        </Grid>
                        <Box display="flex" justifyContent="flex-end" mt={2} marginLeft={2}>
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={handleSave}
                            style={{ marginRight: "10px" }}
                          >
                            Save
                          </Button>
                          <Button variant="outlined" color="secondary" onClick={handleCancel}>
                            Cancel
                          </Button>
                        </Box>
                      </Grid>
                    </Box>
                  )}

                  {/* Accordion for Last 50 Transactions */}
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="transactions-table-content"
                      id="transactions-table-header"
                    >
                      <Typography>Last 50 Transactions</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      {/* Transactions Table */}
                      {walletTransactions.length > 0 ? (
                        <Box style={{ margin: "20px" }}>
                          <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                              <TableHead style={{ backgroundColor: "rgb(224, 224, 224)" }}>
                                <TableRow>
                                  <TableCell className="cellContainer">Type</TableCell>
                                  <TableCell className="cellContainer">Amount</TableCell>
                                  <TableCell className="cellContainer">Description</TableCell>
                                  <TableCell className="cellContainer">Status</TableCell>
                                  <TableCell className="cellContainer">Last Updated</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {walletTransactions.map((row: Transaction) => {
                                  return (
                                    <TableRow
                                      key={row._id}
                                      sx={{
                                        "&:last-child td, &:last-child th": { border: 0 },
                                      }}
                                    >
                                      <TableCell component="th" scope="row">
                                        {row.type}
                                      </TableCell>
                                      <TableCell>{row.amount}</TableCell>
                                      <TableCell>{row.statement}</TableCell>
                                      <TableCell>{row.status}</TableCell>
                                      <TableCell>{readableDate(row.updated_at!)}</TableCell>
                                    </TableRow>
                                  );
                                })}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </Box>
                      ) : (
                        <Typography style={{ textAlign: "center" }}>No Transactions</Typography>
                      )}
                    </AccordionDetails>
                  </Accordion>
                </Box>
              )}
              <Box
                style={{
                  display: "flex",
                  marginTop: "5%",
                  alignItems: "center",
                  justifyContent: "end",
                  marginBottom: "20px",
                  marginRight: "20px",
                }}
              >
                <Snackbar
                  anchorOrigin={{ vertical: "top", horizontal: "right" }}
                  open={failedSnackbar}
                  autoHideDuration={3000}
                  onClose={() => setFailedSnackbar(false)}
                >
                  <Alert
                    onClose={() => setFailedSnackbar(false)}
                    severity="error"
                    sx={{ width: "100%", alignItems: "center" }}
                  >
                    Could not perform this action. Please try again!
                  </Alert>
                </Snackbar>
                {/* Document Verified */}
                <Button
                  variant="contained"
                  style={{ marginRight: "5px" }}
                  onClick={updateDocumentVerified}
                  disabled={
                    driverData.vtcStatus === "document_verified" ||
                    driverData.vtcStatus === "approved" ||
                    driverData.vtcStatus === "bank_details_added"
                  }
                >
                  Documents Verified
                </Button>
                <Snackbar
                  anchorOrigin={{ vertical: "top", horizontal: "right" }}
                  open={documentVerifiedSnackbar}
                  autoHideDuration={3000}
                  onClose={closeDocumentVerifiedSnackbar}
                >
                  <Alert
                    onClose={closeDocumentVerifiedSnackbar}
                    severity="success"
                    sx={{ width: "100%", alignItems: "center" }}
                  >
                    Documents verified!
                  </Alert>
                </Snackbar>
                {/* Add Bank Details */}
                <Button
                  variant="contained"
                  style={{ marginRight: "5px" }}
                  onClick={handleBankDetailsModal}
                  disabled={
                    driverData.vtcStatus === "approved" ||
                    driverData.vtcStatus === "bank_details_added" ||
                    driverData.vtcStatus === "rejected" ||
                    driverData.countryCode === "ivory-coast" ||
                    !driverData.vtcStatus
                  }
                >
                  Add Bank Details
                </Button>
                {/* snackbar here */}
                <Snackbar
                  anchorOrigin={{ vertical: "top", horizontal: "right" }}
                  open={bankDetailsSnackbar}
                  autoHideDuration={3000}
                  onClose={closeSnackBarBankDetails}
                >
                  <Alert
                    onClose={closeSnackBarBankDetails}
                    severity="success"
                    sx={{ width: "100%", alignItems: "center" }}
                  >
                    Bank Details Added!
                  </Alert>
                </Snackbar>
                <Modal
                  open={openAddBankDetailsModal}
                  onClose={handleBankDetailsModalClose}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box
                    sx={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      width: 400,
                      bgcolor: "background.paper",
                      boxShadow: 24,
                      p: 4,
                    }}
                  >
                    <Box>
                      <Box sx={{ display: "flex", justifyContent: "end", marginBottom: 1 }}>
                        <CloseIcon
                          sx={{ cursor: "pointer" }}
                          onClick={handleBankDetailsModalClose}
                        />
                      </Box>
                      <Box style={{ marginBottom: "30px" }}>
                        <span style={{ fontSize: 10 }}>
                          (8,000px X 8,000px, JPG or PNG, less than 10 MB)
                        </span>
                        <Typography style={{ fontWeight: "bold" }} variant="h6" component="h2">
                          Add Verification Document
                        </Typography>
                        <label>
                          Front
                          {/* <input
                            type="file"
                            accept="image/jpeg,image/png,application/pdf"
                            hidden
                            onChange={(e) => handleFileChange(e, setFrontImage, setFrontPreview)}
                          /> */}
                        </label>
                        {frontPreview && (
                          <Box>
                            <img src={frontPreview} alt="Front Preview" width="100" height="100" />
                            {/* <IconButton
                              onClick={() => handleRemove(setFrontImage, setFrontPreview)}
                            >
                              <DeleteIcon />
                            </IconButton> */}
                          </Box>
                        )}

                        <Typography
                          style={{ fontWeight: "bold", marginTop: "20px" }}
                          variant="h6"
                          component="h2"
                        ></Typography>
                        <label>
                          Back
                          {/* <input
                            type="file"
                            accept="image/jpeg,image/png,application/pdf"
                            hidden
                            onChange={(e) => handleFileChange(e, setBackImage, setBackPreview)}
                          /> */}
                        </label>
                        {backPreview && (
                          <Box>
                            <img src={backPreview} alt="Back Preview" width="100" height="100" />
                            {/* <IconButton onClick={() => handleRemove(setBackImage, setBackPreview)}>
                              <DeleteIcon />
                            </IconButton> */}
                          </Box>
                        )}
                      </Box>
                      <Box style={{ marginBottom: "30px" }}>
                        <Typography
                          style={{ fontWeight: "bold" }}
                          id="modal-modal-title"
                          variant="h6"
                          component="h2"
                        >
                          Add Bank Details
                        </Typography>
                      </Box>
                      <Box
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "start",
                          marginBottom: "20px",
                        }}
                      >
                        {/* for UK user */}
                        <FormControl component="fieldset">
                          {driverData.countryCode === "france" ? (
                            <>
                              <TextField
                                id="outlined-basic"
                                size="small"
                                placeholder="Account Holder Name"
                                label="Account Holder name"
                                name="Account Holder Name"
                                value={franceAccountHolderName}
                                onChange={(e) => setFranceAccountHolderName(e.target.value)}
                                variant="outlined"
                                style={{ marginBottom: "10px", width: "100%" }}
                              />
                              <TextField
                                id="outlined-basic"
                                size="small"
                                placeholder="IBAN"
                                label="IBAN"
                                name="IBAN"
                                value={ibanNumber}
                                onChange={(e) => setIbanNumber(e.target.value)}
                                variant="outlined"
                                style={{ marginBottom: "10px", width: "100%" }}
                              />
                            </>
                          ) : (
                            <>
                              <TextField
                                id="outlined-basic"
                                size="small"
                                placeholder="Account Holder Name"
                                label="Account Holder name"
                                name="Account Holder Name"
                                value={ukAccountHolderName}
                                onChange={(e) => setUkAccountHolderName(e.target.value)}
                                variant="outlined"
                                style={{ marginBottom: "10px", width: "100%" }}
                              />
                              <TextField
                                id="outlined-basic"
                                size="small"
                                placeholder="Account Number"
                                label="Account Number"
                                name="Account Number"
                                value={ukAccountNumber}
                                onChange={(e) => setUkAccountNumber(e.target.value)}
                                variant="outlined"
                                style={{ marginBottom: "10px", width: "100%" }}
                              />
                              <TextField
                                id="outlined-basic"
                                size="small"
                                placeholder="Sort Code"
                                label="Sort Code"
                                name="Sort Code"
                                value={sortCode}
                                onChange={(e) => setSortCode(e.target.value)}
                                variant="outlined"
                                style={{ marginBottom: "10px", width: "100%" }}
                              />
                            </>
                          )}
                        </FormControl>
                      </Box>
                    </Box>
                    <Box sx={{ display: "flex", justifyContent: "end" }}>
                      <Button
                        variant="contained"
                        onClick={() => handleBankDetailsSubmit(driverData.countryCode)}
                        disabled={savingBankDetailsLoader}
                      >
                        {savingBankDetailsLoader ? "Saving..." : "Submit"}
                      </Button>
                    </Box>
                  </Box>
                </Modal>

                {/* reject */}
                <Button variant="contained" style={{ marginRight: "5px" }} onClick={handleOpen}>
                  Reject
                </Button>
                <Snackbar
                  anchorOrigin={{ vertical: "top", horizontal: "right" }}
                  open={rejectSnackbar}
                  autoHideDuration={3000}
                  onClose={closeRejectSnackBar}
                >
                  <Alert
                    onClose={closeRejectSnackBar}
                    severity="error"
                    sx={{ width: "100%", alignItems: "center" }}
                  >
                    Driver is rejected!
                  </Alert>
                </Snackbar>
                <Modal
                  open={openRejectDocModal}
                  onClose={handleClose}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box
                    sx={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      width: 400,
                      bgcolor: "background.paper",
                      boxShadow: 24,
                      p: 4,
                    }}
                  >
                    <Box>
                      <Box sx={{ display: "flex", justifyContent: "end", marginBottom: 1 }}>
                        <CloseIcon sx={{ cursor: "pointer" }} onClick={handleClose} />
                      </Box>
                      <Box style={{ marginBottom: "30px" }}>
                        <Typography
                          style={{ fontWeight: "bold" }}
                          id="modal-modal-title"
                          variant="h6"
                          component="h2"
                        >
                          Documents Checklist
                        </Typography>
                      </Box>
                      <Box
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "start",
                          marginBottom: "20px",
                        }}
                      >
                        {/* Checkboxes for document titles */}
                        <FormControl component="fieldset">
                          <FormLabel component="legend">
                            * Please select the rejected documents:
                          </FormLabel>
                          <FormGroup>
                            {Object.entries(documentTitles).map(([title, value]) => (
                              <FormControlLabel
                                key={title}
                                control={
                                  <Checkbox
                                    checked={selectedDocuments.includes(title)}
                                    onChange={handleCheckboxChange}
                                    name={title}
                                  />
                                }
                                label={value}
                              />
                            ))}
                          </FormGroup>
                        </FormControl>
                      </Box>
                    </Box>
                    <Box style={{ marginBottom: "20px" }}>
                      {/* Remarks section */}
                      <Typography variant="subtitle1" style={{ fontWeight: "bold" }}>
                        * Remarks
                      </Typography>
                      <Editor value={rejectRemark} onChange={onHandleChange}>
                        <Toolbar>
                          <BtnRedo />
                          <BtnUndo />
                          <BtnBold />
                          <BtnItalic />
                          <BtnBulletList />
                          <BtnNumberedList />
                          <BtnUnderline />
                          <BtnLink />
                          <BtnClearFormatting />
                        </Toolbar>
                      </Editor>
                    </Box>
                    <Box sx={{ display: "flex", justifyContent: "end" }}>
                      <Button
                        variant="contained"
                        onClick={rejectDocument}
                        disabled={selectedDocuments.length === 0 || !rejectRemark}
                      >
                        Submit
                      </Button>
                    </Box>
                  </Box>
                </Modal>

                {/* approve */}
                <Button
                  variant="contained"
                  disabled={
                    driverData.vtcStatus === "approved" ||
                    (driverData.countryCode !== "ivory-coast" &&
                      driverData.vtcStatus === "document_verified") ||
                    driverData.vtcStatus === "rejected" ||
                    !driverData.vtcStatus
                  }
                  onClick={approveDocument}
                >
                  Approve
                </Button>
                <Snackbar
                  anchorOrigin={{ vertical: "top", horizontal: "right" }}
                  open={approveSnackbar}
                  autoHideDuration={3000}
                  onClose={closeSnackBar}
                >
                  <Alert
                    onClose={closeSnackBar}
                    severity="success"
                    sx={{ width: "100%", alignItems: "center" }}
                  >
                    Driver is approved!
                  </Alert>
                </Snackbar>
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default DriverDetails;
