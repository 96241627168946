export enum TransactionType {
  DEBIT = "DEBIT",
  CREDIT = "CREDIT",
}

export enum TransactionStatus {
  ACCEPTED = "ACCEPTED",
  SUBMITTED = "SUBMITTED",
  COMPLETED = "COMPLETED",
  FAILED = "FAILED",
  STARTED = "STARTED",
}
export interface TransactionResponse {
  limit: number;
  page: number;
  total: number;
  transactions: Transaction[];
}
export interface Transaction {
  _id?: string;
  wallet_id: string;
  user_id: string;
  amount: number;
  type: TransactionType;
  currency: string;
  status?: TransactionStatus;
  statement: string;
  created_at?: string;
  updated_at?: string;
}
export interface Wallet {
  _id: string;
  user_id: string;
  wallet_balance: number;
  currency: string;
  country_code: string;
  region_code: string;
  created_at: string;
  updated_at: string;
}
