import axios from "axios";
import { User, UserResponse } from "../types/users";

const BASE_URL = process.env.REACT_APP_AUTH_SERVICE_BASE_URL;

export const userService = {
  getUsers: async (filters: Record<string, string | number>) => {
    const token = localStorage.getItem("token");
    const { data } = await axios.get<UserResponse>(`${BASE_URL}/auth/v1/users/get-users`, {
      headers: { Authorization: `Bearer ${token}` },
      params: filters,
    });
    return data;
  },
  deleteUser: async (id: string) => {
    await axios.delete(`${BASE_URL}/users/${id}`);
  },
};
