// Importing necessary modules from React Router and React
import React, { useEffect, useState } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";

// Importing components/screens used in the application
import Login from "./screens/Login";
import Dashboard from "./screens/Dashboard";
import Register from "./screens/Register";
import ForgotPassword from "./screens/ForgotPassword";
import Discounts from "./screens/Discounts";
import DriverDocumentation from "./screens/DriverDocumentation";
import DriverDetails from "./screens/DriverDocumentation/DriverDetails";
import Pricing from "./screens/Pricing";
import Earnings from "./screens/Earnings";
import ReferralPlans from "./screens/ReferralPlans";
import ReferralTracker from "./screens/ReferralTracker";
import SurgeEvents from "./screens/SurgeEvents";
import CreateDiscount from "./screens/Discounts/CreateDiscount";
import EditDiscount from "./screens/Discounts/EditDiscount";
import CreateReferralPlan from "./screens/ReferralPlans/CreateReferralPlan";
import EditEarning from "./screens/Earnings/EditEarnings";
import EditReferralPlan from "./screens/ReferralPlans/EditReferralPlan";
import EditPricing from "./screens/Pricing/EditPricing";
import CreateSurgeEvents from "./screens/SurgeEvents/CreateSurgeEvents";
import EditSurgeEvents from "./screens/SurgeEvents/EditSurgeEvents";
import ProtectedRoutes from "./ProtectedRoutes";
import NotFound from "./screens/NotFound";
import WebSocketComponent from "./screens/WebSocketUI";
import CreatePricing from "./screens/Pricing/CreatePricing";
import { jwtDecode } from "jwt-decode";
import { EditorProvider } from "react-simple-wysiwyg";
import AddEditNotificationTemplate from "./screens/NotificationTemplates/NotificationTemplateForm";
import UserManagement from "./screens/users/UserManagement";
import NotificationTemplateManagement from "./screens/NotificationTemplates/NotificationTemplatesList";
// Main Router component
const Router = () => {
  const [decodedToken, setDecodedToken] = useState<any>(null);
  // Checking if a user is logged in by retrieving the token from local storage
  const token = localStorage.getItem("token");

  // Navigation hook from React Router
  let navigate = useNavigate();

  // Example driver details, could be fetched dynamically based on authentication
  const driverDetails = {
    id: 1,
    full_name: "John Doe",
    driver_license_number: "AD56566666",
    car_id: "XY343232",
    total_number_of_rides: 40,
    availability: "XYZ",
    documents: "DD20",
  };

  // useEffect hook (currently empty)
  useEffect(() => {
    // Add any side effects or initializations if needed
    if (token) {
      const decoded = jwtDecode(token);
      setDecodedToken(decoded);
    }
  }, [token]);

  // JSX structure for defining routes using React Router
  return (
    <div>
      <Routes>
        {/* Route for the homepage, showing either the Dashboard or Login based on the token */}
        <Route path="/" element={token ? <Dashboard /> : <Login />} />

        {/* Protected routes that require authentication */}
        <Route element={<ProtectedRoutes />}>
          {/* Routes for various screens and actions */}
          <Route path="/register-new-user" element={<Register />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/discounts" element={<Discounts />} />
          <Route path="/discounts/create" element={<CreateDiscount />} />
          <Route path="/discounts/edit/:id" element={<EditDiscount />} />
          <Route
            path="/referral-plans"
            element={decodedToken?.role === "admin" ? <ReferralPlans /> : <NotFound />}
          />
          <Route
            path="/referral-plans/create"
            element={decodedToken?.role === "admin" ? <CreateReferralPlan /> : <NotFound />}
          />
          <Route
            path="/referral-plans/edit/:id"
            element={decodedToken?.role === "admin" ? <EditReferralPlan /> : <NotFound />}
          />
          <Route path="/driver-documentation" element={<DriverDocumentation />} />
          <Route
            path="/driver-documentation/:id"
            element={
              <EditorProvider>
                <DriverDetails />
              </EditorProvider>
            }
          />
          <Route
            path="/pricing"
            element={decodedToken?.role === "admin" ? <Pricing /> : <NotFound />}
          />
          <Route
            path="/pricing/create"
            element={decodedToken?.role === "admin" ? <CreatePricing /> : <NotFound />}
          />
          <Route
            path="/pricing/edit/:id"
            element={decodedToken?.role === "admin" ? <EditPricing /> : <NotFound />}
          />
          <Route
            path="/surge-events"
            element={decodedToken?.role === "admin" ? <SurgeEvents /> : <NotFound />}
          />
          <Route
            path="/surge-events/create"
            element={decodedToken?.role === "admin" ? <CreateSurgeEvents /> : <NotFound />}
          />
          <Route
            path="/surge-events/edit/:id"
            element={decodedToken?.role === "admin" ? <EditSurgeEvents /> : <NotFound />}
          />
          {/* <Route
            path="/notifications"
            element={decodedToken?.role === "admin" ? <Notifications /> : <NotFound />}
          /> */}
          <Route
            path="/earnings-and-management"
            element={decodedToken?.role === "admin" ? <Earnings /> : <NotFound />}
          />
          <Route
            path="/earnings-and-management/edit/:id"
            element={decodedToken?.role === "admin" ? <EditEarning /> : <NotFound />}
          />
          <Route
            path="/referral-tracking"
            element={decodedToken?.role === "admin" ? <ReferralTracker /> : <NotFound />}
          />
          <Route path="/websocket-ui" element={<WebSocketComponent />} />
          <Route
            path="/notifications"
            element={
              decodedToken?.role === "admin" ? <NotificationTemplateManagement /> : <NotFound />
            }
          />
          <Route
            path="/users"
            element={decodedToken?.role === "admin" ? <UserManagement /> : <NotFound />}
          />
          <Route
            path="/notifications/create"
            element={
              decodedToken?.role === "admin" ? (
                <EditorProvider>
                  <AddEditNotificationTemplate />{" "}
                </EditorProvider>
              ) : (
                <NotFound />
              )
            }
          />
          <Route
            path="/notifications/edit/:id"
            element={
              decodedToken?.role === "admin" ? (
                <EditorProvider>
                  <AddEditNotificationTemplate />{" "}
                </EditorProvider>
              ) : (
                <NotFound />
              )
            }
          />
        </Route>

        {/* Catch-all route for handling 404 (Not Found) errors */}
        <Route path="*" element={<NotFound />}></Route>
      </Routes>
    </div>
  );
};

// Exporting the Router component
export default Router;
