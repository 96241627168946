import { Transaction } from "./../types/wallet";
import axios from "axios";

const baseUrl = process.env.REACT_APP_ORDERS_SERVICE_BASE_URL;
const token = localStorage.getItem("token");

export const getWallet = async (id: string, countryCode: string, regionCode: string) => {
  let response: any;
  await axios
    .get(`${baseUrl}/api/v1/wallet/balance/${id}/${countryCode}/${regionCode}`, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then((res) => {
      response = res.data.data;
    })
    .catch((e) => {
      console.log("Error:", e);
      response = {};
    });
  console.log("res::", response);
  return response;
};

export const getTransactionsList = async (id: string) => {
  let response: any;
  await axios
    .get(`${baseUrl}/api/v1/wallet/transactions/${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then((res) => {
      response = res.data.data;
    })
    .catch((e) => {
      console.log("Error:", e);
      response = {};
    });
  console.log("res::", response);
  return response;
};

export const handleTransactionUpdate = async (payload: Transaction) => {
  let response: any;
  await axios
    .post(`${baseUrl}/api/v1/wallet/transactions`, payload, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then((res) => {
      response = res.data.data;
    })
    .catch((e) => {
      console.log("Error:", e);
      response = {};
    });
  console.log("res::", response);
  return response;
};
