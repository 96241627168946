import React, { useState } from "react";
import { Box, Tabs, Tab, Paper } from "@mui/material";
import SidePanel from "../../components/SidePanel";
import TopBar from "../../components/TopBar";
import DriverTable from "./DriverTable";
import RiderTable from "./RiderTable";

const UserManagement: React.FC = () => {
  const [activeTab, setActiveTab] = useState(0); // Track the active tab

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  return (
    <Box className="mainContainer">
      <Box className="sidePanelContainer">
        <SidePanel isOpen={true} onClose={() => {}} />
      </Box>
      <Box className="dashboardContainer">
        <TopBar />
        <Box sx={{ width: "100%", padding: "20px", marginTop: "5%" }}>
          <Paper elevation={3} sx={{ padding: "20px" }}>
            <Tabs value={activeTab} onChange={handleTabChange}>
              <Tab label="Drivers" />
              <Tab label="Riders" />
            </Tabs>
            {activeTab === 0 && <DriverTable />}
            {activeTab === 1 && <RiderTable />}
          </Paper>
        </Box>
      </Box>
    </Box>
  );
};

export default UserManagement;
